import './Extra.css';
import 'react-accessible-accordion/dist/fancy-example.css';

import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Helmet } from 'react-helmet';

import AnimatedBox from '../../components/AnimatedBox';

export default function FAQ() {
  return (
    <div>
      <Helmet>
        <title>
          Presolv360 | FAQs | ADR | Online Arbitration | Online Negotiation
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="msapplication-TileImage"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />

        <meta
          name="description"
          content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally."
        />

        <meta
          name="keywords"
          content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India"
        />

        <meta
          property="og:keywords"
          content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India"
        />
        <meta
          property="og:description"
          content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally."
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />
        <meta
          property="og:image:secure_url"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />
        <meta property="og:url" content="https://www.presolv360.com" />
        <meta
          property="og:title"
          content=" Presolv360 | Dispute resolution made easy"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="firstBreak extraPage">
        <section className="landing_wrap">
          {window.innerWidth > 600 ? (
            <h1 style={{ padding: "0 1.5em" }}>Frequently Asked Questions</h1>
          ) : (
            <h1>Frequently Asked Questions</h1>
          )}
          <AnimatedBox />
        </section>
      </div>
      <div className="extra_section_wrap faqPage">
        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>What is Presolv360 ?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Presolv360 is a neutral and independent platform providing
              Alternative Dispute Resolution (“ADR”), as well as Online Dispute
              Resolution (“ODR”) services, via its proprietary dispute
              resolution modules.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>What is ODR ?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              ODR involves using legally recognized ADR mechanisms to resolve
              disputes in an online environment. It is a modern counterpart of
              ADR and refers to the use of technology to resolve disputes
              out-of-court, by adopting various mechanisms such as arbitration,
              conciliation, mediation, negotiation or a combination thereof
              (collectively referred to as “ADR Process”). It has the potential
              to solve for one of the most challenging problems faced by
              humanity –‘access to justice’.{" "}
              <a href="https://drive.google.com/file/d/13kDlOAF414cSrDjt7yYNsWbP4JMb8Fog/view">
                Click here{" "}
              </a>
              to access Presolv360’s concept note on ODR.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Is ODR a legally recognized way of resolving disputes ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              ODR is not a separate mechanism, but conduct of the ADR Process in
              an online environment. Accordingly, the law not only permits ODR,
              but it has also gained wide recognition in India from the
              judiciary, the government and business enterprises.{" "}
              <a href="https://uncitral.un.org/sites/uncitral.un.org/files/media-documents/uncitral/en/v1700382_english_technical_notes_on_odr.pdf">
                Click here
              </a>{" "}
              to view the Technical Notes on Online Dispute Resolution by United
              Nations Commission On International Trade Law.{" "}
              <a href="https://www.niti.gov.in/sites/default/files/2021-11/odr-report-29-11-2021.pdf">
                Click here
              </a>{" "}
              to read the report by The NITI Aayog Expert Committee on ODR.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Why choose Presolv360 ?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {" "}
                Presolv360 is a pioneer of ODR and one of India's most trusted
                platform for dispute resolution. It is the one of the only ODR
                platforms to be empaneled as a Mediation Institution by various
                Courts in India and has also been incubated by India’s leading
                law firm Cyril Amarchand Mangaldas.
              </p>{" "}
              <p>
                {" "}
                Presolv360’s advisory council consists of Justice U U Lalit,
                former Chief Justice of India, Justice B N Srikrishna, former
                Judge of the Supreme Court of India and Justice K Kannan, former
                High Court Judge.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What is the role of Presolv360 in resolving disputes ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Presolv360 does not undertake any adjudicatory functions and only
              provides administrative assistance for conduct of the ADR Process
              on its virtual platform. It empanels independent, qualified
              neutrals with the required competence, knowledge and expertise on
              its panel. Being a neutral and independent platform, Presolv360
              has no interest in the outcome of the dispute or any conflict of
              interest with the dispute or the disputing parties.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How are the disputes resolved on Presolv360’s ODR platform ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Presolv360 administers dispute resolution via its proprietary
                modules, ‘Arbitration360’ and ‘Mediation360’.{" "}
                <a
                  href="https://www.youtube.com/watch?v=BgEy12SijzY&t=3s"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>{" "}
                to watch an explainer video.
              </p>
              <p>
                Arbitration360 refers to Presolv360’s electronic arbitration
                module wherein disputing parties are bound by an award made by
                the arbitrator. Parties opt for arbitration in the event they
                desire a decision by an independent adjudicator.
              </p>
              <p>
                Mediation360 refers to Presolv360’s electronic mediation module
                wherein disputing parties mutually opt to settle the dispute
                with the help of an independent neutral. Parties shall opt for
                mediation in the event they desire a negotiated settlement. The
                Mediation360 module can also be used to facilitate
                conciliations, negotiations or a combination thereof.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How do I provide for ODR in my contract ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              To incorporate ODR as a dispute resolution mechanism in your
              contract :
              <ul>
                <li>
                  Insert an appropriate clause under the heading ‘Dispute
                  Resolution’ or under a similar section in the contract;
                  [Please ensure that there are no conflicting dispute
                  resolution clauses under the heading “Dispute Resolution” or
                  “Arbitration” or under a similar section in the contract. In
                  case the contract is already executed, parties can still opt
                  for ODR mechanisms by agreeing to it by an exchange of letters
                  / emails.]
                </li>
                <li>
                  The ‘Notice’ clause in the contract should enable parties to
                  serve notices / communications by email, text message and
                  tele-messaging applications such as WhatsApp; and
                </li>
                <li>
                  The ‘Governing Law’ clause in the contract states that the
                  contract is governed by and construed in accordance with the
                  laws prevalent in India.
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Does Presolv360 have a model clause and rules governing the
                dispute resolution proceedings ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <a
                href="https://presolv360.com/clause"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{" "}
              to view the model clause.{" "}
              <a
                href="https://presolv360.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{" "}
              to access the governing documents for resolving disputes on
              Presolv360’s ODR platform.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What are the main features of Presolv360’s ODR platform ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <ul>
                <li>
                  Cloud-based end-to-end ODR platform accessible from desktops,
                  laptops and smartphones
                </li>
                <li>Smart case management tools</li>
                <li>
                  Email, WhatsApp and SMS integration along with real-time
                  tracking
                </li>
                <li>Automated phone system technology</li>
                <li>
                  Automated case allocation tool to randomize the process of
                  assignment of neutral
                </li>
                <li>Automated document workflow</li>
                <li>Pre-formatted documents</li>
                <li>E-signatures and e-stamping</li>
                <li>E-signatures and e-stamping</li>
                <li>Automated settlement builders</li>
                <li>Blockchain-based trace trail of proceedings</li>
                <li>Machine-intelligent chatbots</li>
                <li>Auto-generated reports on dispute trends</li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How can arbitrators or mediators empanel themselves with
                Presolv360 ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              The empanelment is based on profile verification, tutorials,
              trainings and performance evaluation. Such empanelment does not
              entail any fee. Drop an email to{" "}
              <a href="mailto:info@presolv360.com">info@presolv360.com</a> to
              begin the empanelment process.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                When can a dispute be resolved on Presolv360’s ODR platform ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              A dispute can be resolved on Presolv360’s ODR platform when :
              <ul>
                <li>
                  The contract contains an appropriate dispute resolution
                  clause;
                </li>
                <li>
                  Acceptance of invitation to resolve on Presolv360’s ODR
                  platform;
                </li>
                <li>Parties make a joint application; or</li>{" "}
                <li>
                  Dispute is referred by a court, tribunal or regulatory
                  authority.
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Can a dispute be resolved on Presolv360’s ODR platform without
                an ADR or ODR clause ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Yes. Once a dispute is registered on Presolv360’s ODR platform by
              a party, an invitation will be sent by Presolv360 to the other
              party. Once accepted, the ADR Process will commence on
              Presolv360’s ODR platform.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How will notices, updates, information and documents be
                communicated to the parties ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              All notices, updates, information and documents will be
              communicated in real-time via email and messaging applications
              such as SMS / WhatsApp and can also be viewed in real-time by
              logging in to Presolv360’s ODR platform.{" "}
              <a
                href="https://drive.google.com/file/d/1r9VrmyKjOW6Ph4TpndE7mA7fBqgLvWSm/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{" "}
              to read about service through emails, WhatsApp and messaging
              applications.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How will oral hearing be conducted ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Oral hearings can be conducted virtually on party-preferred audio
              / video-conferencing applications.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What are the fees to resolve a dispute on Presolv360’s ODR
                platform ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              The fees will be determined on the basis of the number of parties,
              quantum of claim and complexity of the dispute. To know the fee
              applicable to you, contact us at{" "}
              <a href="mailto:info@presolv360.com">info@presolv360.com</a> .
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How much time does it take to resolve a dispute on Presolv360’s
                ODR platform ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Depending on the dispute and the mechanism employed, resolutions
              have been achieved in as little as 3 hours to an average of 27
              days
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What do I do when I receive a notice / invitation from
                Presolv360 ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Presolv360 provides 24*7 administrative support to each party. In
              case you have received a notice from Presolv360, and you would
              like to respond, negotiate or propose a settlement, drop an email
              to <a href="mailto:admin@presolv360.com">admin@presolv360.com</a>{" "}
              and include your case ID in the subject line.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Can Presolv360 provide legal advice in respect of the dispute ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Presolv360 is not a law firm and does not provide legal advice.
              Only a legal practitioner can provide legal advice. A legal
              practitioner should be consulted for any legal advice or matter.
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How will Presolv360 assign an arbitrator or mediator to the
                dispute ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Upon registration of a dispute, a neutral is assigned on behalf of
              all the parties after evaluating various factors such as :
              <ul>
                <li>Dispute: nature, complexity and quantum</li>
                <li>
                  Neutral: area of expertise, availability and ability to
                  conduct the proceedings neutrally and fairly
                </li>
                <li>Parties: independence with the neutral</li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Can parties be represented by an authorized representative or a
                lawyer in the resolution proceedings ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Parties may choose to be represented or assisted through an
              authorized representative for the proceedings. However, the
              professional charges of the authorized representative, cost of
              appointment and representation or any other incidental costs shall
              be borne by the appointing party. In relation to the arbitration
              proceedings, the arbitrator will have the discretion to determine
              whether costs are payable by one party to another, the amount of
              such costs and when such costs are to be paid.{" "}
              <a
                href="https://drive.google.com/file/d/1Q1d6_3n3R1QimVhtb1eGFC2jG3cWk7pS/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{" "}
              to download the format of the Letter of Authority.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What happens if the responding party does not participate in the
                proceedings ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Mediation / conciliation / negotiation proceedings are voluntary
                in nature, and require consent and participation of all parties
                to the dispute. In this case, non-participation would render the
                proceedings a non-starter and the matter will not be proceeded
                with.
              </p>
              <p>
                In case of arbitration, notwithstanding non-participation by a
                party, the arbitration proceedings may continue and the
                arbitrator may proceed with the adjudication of the dispute
                based on the evidence before the arbitrator. It is recommended
                that all parties should participate in the proceedings for
                proper resolution of the dispute.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Is the outcome legally binding and enforceable ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              As per the Arbitration and Conciliation Act, 1996, an arbitration
              award is final and binding on the parties and enforceable as if it
              were a decree of the court. A settlement agreement made in
              pursuance to mediation proceedings shall be final and binding on
              the parties. The terms of settlement may also be recorded in a
              conciliation agreement and it shall have the same status and
              effect as if it is an arbitral award.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What happens after an arbitral award is passed ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Parties are required to carry out the arbitral award immediately
              and without delay to avoid serious consequences as provided under
              the law. Challenge to an arbitral award may be made by approaching
              a court of competent jurisdiction within the prescribed time
              limit. Click here to view the model clause. Click here to access
              the governing documents for resolving disputes on Presolv360’s ODR
              platform.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Can parties communicate directly with the assigned neutral ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              To maintain integrity and independence of the ADR Process, parties
              shall not unilaterally communicate with the assigned neutral and
              the neutral shall not communicate unilaterally with any party. Any
              and all communication with the neutral shall be through
              Presolv360.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Is it safe and secure to conduct dispute resolution proceedings
                online ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Presolv360 has implemented appropriate safeguards to prevent
              unauthorized access to any party’s information or documents and to
              maintain data security. Presolv360 employs encryption technology
              for protecting sensitive information. Presolv360 follows generally
              accepted industry standards to protect the information and
              documents submitted on the Presolv360’s ODR platform. However, no
              method of electronic transmission or storage is 100% secure. While
              Presolv360 strives to use commercially acceptable means to protect
              the information and documents of the parties, Presolv360 does not
              guarantee its absolute security and assumes no liability or
              responsibility for disclosure of any party’s information due to
              errors in transmission, unauthorized third-party access, or other
              causes beyond Presolv360’s control. The parties play an important
              role in keeping its information secure. Parties should not share
              its account information with any third party.{" "}
              <a
                href="https://presolv360.com/odr_standard"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{" "}
              to view the ODR standards adopted by Presolv360.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Are the resolution proceedings confidential ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              While the platform, technology and process have been designed and
              developed keeping in mind confidentiality obligations applicable
              to dispute resolution, the applicable laws bind the parties,
              practitioners and providers to confidentiality. Accordingly, all
              proceedings facilitated and administered on Presolv360’s ODR
              platform are confidential except where disclosure is necessary for
              purposes of implementation and enforcement.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Is ODR effective and what are its benefits ?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              ODR proceedings entail the same validity and enforceability as the
              ADR Process. Further, ODR has already brought tremendous value to
              parties and experts for a variety of disputes as it is a quick,
              convenient and cost-effective way of resolving them.
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
