import "../../assets/styles/Roletemplatesearch.css";

import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { ArrowUpRight, CircleChevronLeft, Search } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

export default function RoleTemplateSearch() {
  const { role } = useParams();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://cms.presolv360.com/api/templates?filters[Category][$eq]=${role}`
        );
        setTemplates(response.data.data || []);
      } catch (error) {
        console.error("Error fetching templates:", error);
        toast.error("Failed to fetch templates. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, [role]);

  const filteredTemplates = useMemo(() => {
    return templates.filter((template) =>
      template.Label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [templates, searchQuery]);

  const totalItems = filteredTemplates.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredTemplates.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredTemplates, currentPage]);

  const handlePageChange = (_, value) => {
    setCurrentPage(value);
  };

  const handleOpenInNewWindow = (template) => {
    const content = template.HTML;
    const filename = template.Label.replace(/\s+/g, "_");
    const newWindow = window.open("", "_blank");

    if (newWindow) {
      newWindow.document.open();
      newWindow.document.write(`
        <html>
          <head>
            <title>${template.Label}</title>
            <style>
              body { font-family: Arial, sans-serif; padding: 20px; text-align: center; }
              .template-content { border: 2px solid #ccc; padding: 20px; margin: auto; width: 800px; }
              button { margin-top: 10px; padding: 8px 16px; font-size: 16px; cursor: pointer; }
            </style>
            <script>
              function download(format) {
                var content = ${JSON.stringify(content)};
                var filename = "${filename}";
                var blob = new Blob([content], { type: format === "doc" ? "application/msword" : "text/html" });
                var downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename + (format === "doc" ? ".doc" : ".html");
                downloadLink.click();
              }
            </script>
          </head>
          <body>
            <h1>${template.Label}</h1>
            <div class="template-content">${content}</div>
            <button onclick="download('html')">Download HTML</button>
            <button onclick="download('doc')">Download DOC</button>
          </body>
        </html>
      `);
      newWindow.document.close();
    }
  };

  return (
    <div className="container_1">
      <ToastContainer />

      {/* Back Button */}
      <div className="back-button_1" onClick={() => navigate(-1)}>
        <CircleChevronLeft className="back-icon" />
      </div>

      {/* Search Bar */}
      <div className="search-bar">
        <div className="search-input-container">
          <Search className="search-icon" />
          <input
            type="text"
            placeholder={`Search templates for ${role}`}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            className="search-input"
            style={{ paddingLeft: "10px" }}
          />
        </div>
        <button className="search-button">Search</button>
      </div>

      {/* Templates List */}
      <div className="template-grid">
        {loading ? (
          <p>Loading templates...</p>
        ) : currentItems.length > 0 ? (
          currentItems.map((template) => (
            <div
              key={template.id}
              className="card_2"
              onClick={() => handleOpenInNewWindow(template)}
            >
              <div className="card-content">
                <span className="card-title">{template.Label}</span>
                <ArrowUpRight className="card-icon_2" />
              </div>
            </div>
          ))
        ) : (
          <p>No templates found.</p>
        )}
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      )}
    </div>
  );
}
