import "./Extra.css";

import React from "react";
import { Helmet } from "react-helmet";

import AnimatedBox from "../../components/AnimatedBox";

export default function TermsCondition() {
  return (
    <div>
      <Helmet>
        <title>
          Presolv360 | Terms and Conditions | Alternative Dispute Resolution
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="msapplication-TileImage"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />

        <meta
          name="description"
          content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally."
        />

        <meta
          name="keywords"
          content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India"
        />

        <meta
          property="og:keywords"
          content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India"
        />
        <meta
          property="og:description"
          content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally."
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />
        <meta
          property="og:image:secure_url"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />
        <meta property="og:url" content="https://www.presolv360.com" />
        <meta
          property="og:title"
          content=" Presolv360 | Dispute resolution made easy"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="firstBreak extraPage">
        <section className="landing_wrap">
          {window.innerWidth > 600 ? (
            <h1 style={{ padding: "0 1.5em" }}>Terms and Conditions</h1>
          ) : (
            <h1>Terms and Conditions</h1>
          )}
          <AnimatedBox />
        </section>
      </div>
      <div className="extra_section_wrap">
        <h3>Introduction</h3>
        <p>
          These Terms and Conditions (“Terms” or “Agreement”) is an electronic
          record in the form of an electronic contract formed under the
          Information Technology Act, 2000, and the rules made thereunder.
        </p>
        <p>
          The terms “we / us / our / platform / Presolv360”, individually and
          collectively, refer to https://www.presolv360.com/; and the terms “you
          / your / yourself / user” refer to any legal person or entity
          accessing the platform who is competent to enter into binding
          contracts, as per the provisions of the Indian Contract Act, 1872.
        </p>
        <p>
          Please read the Terms carefully. By using the platform, you indicate
          that you understand, agree and consent to the Terms. If you do not
          agree with the Terms, please do not use the platform. The user
          expressly agrees that we retain the sole and exclusive right to amend
          or modify the Terms and the{" "}
          <a
            href="https://presolv360.com/privacy_policy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>{" "}
          (“Policy”) without any prior permission or intimation to the user, and
          the user expressly agrees that any such amendments or modifications
          shall come into effect immediately.
        </p>
        <p>
          Presolv360 is an online dispute resolution platform built for quick,
          economical and effective resolution of your legal disputes. We have
          created an electronic arbitration module called as ‘Arbitration360’
          and an electronic mediation module called as ‘Mediation360’ by
          blending technology, innovation and human expertise to bring dispute
          resolution right at your fingertips. They are collectively referred to
          as “Services”.
        </p>
        <p>
          Your use of the platform is governed by the Terms and the Policy. Each
          time you use the platform, the current version of the Terms will
          govern your use. Accordingly, each time you use our platform, you
          should check the date at the bottom of this Agreement’s page to be
          sure that you’ve read the most recent version.
        </p>
        <p>
          You unequivocally agree that the Terms and the Policy constitute a
          legally binding agreement between you and us, and that you shall be
          subject to the terms applicable to any service that is provided by the
          platform, and that the same shall be deemed to be incorporated into
          the terms, and shall be treated as part and parcel of the same. You
          acknowledge and agree that no physical or electronic signature or
          express act is required to make the Terms and the Policy binding on
          you, and that your act of visiting any part of the platform
          constitutes your full and final acceptance of the Terms and the
          Policy.
        </p>
        <h3>Disclaimer</h3>
        <p>
          The Company is not a law firm and does not provide legal advice.
          Services provided or use of any material provided by the Company is
          not a substitute for legal advice. Only a legal practitioner can
          provide legal advice. A legal practitioner should be consulted for any
          legal advice or matter. No attorney-client relationship is created by
          receiving services or use of materials provided by us. We engage
          various legal experts for provision of legal services.
        </p>
        <h3>Eligibility</h3>
        <p>
          The Services provided through the platform are available only to
          persons who are competent to enter into a legally binding contract
          under the Indian Contract Act, 1872. The platform does not cater to
          individuals less than 18 years of age. By accessing or using the
          platform, you represent that you are 18 years or older. In the case of
          a minor (under 18 years of age), a parent or guardian must accept the
          Terms and guide all consultations under the Services offered.
        </p>
        <h3>Registration of User for Availing Services</h3>
        <p>
          In order to avail our Services, you need to register on Presolv360 by
          creating an account by providing your details, including but not
          limited to first name, last name, office or residential address,
          nationality, e-mail address and mobile number (individually and
          collectively referred as “Personal Information”), on the ‘Login /
          Create Account’ page of the platform. You are required to provide
          information about yourself that is true, accurate, current and
          complete in all respects. Should any of your Personal Information
          change, do notify us via e-mail at{" "}
          <a href="mailto:info@presolv360.com" rel="noreferrer" target="_blank">
            info@presolv360.com
          </a>
          .
        </p>
        <h3>User Account, Password and Security</h3>
        <p>
          Once you complete the registration process, you are responsible for
          maintaining the confidentiality of your account’s username and
          password and are fully responsible for all activities that occur under
          your account.
        </p>
        <p>
          You shall immediately notify Presolv360 via email at{" "}
          <a href="mailto:info@presolv360.com" target="_blank" rel="noreferrer">
            info@presolv360.com
          </a>{" "}
          with full details if: (a) you discover or suspect unauthorized access
          to your account; (b) you notice discrepancies that might be
          attributable to unauthorized access; (c) you forget your username and
          / or password; or (d) you discover a security flaw on the platform.
        </p>
        <h3>Communication</h3>
        <p>
          By providing your Personal Information to us, you agree that we may
          send you informational / promotional text (SMS) messages and e-mail
          notifications. You may opt-out of receiving informational /
          promotional text (SMS) messages or e-mail notification from us at any
          time by intimating{" "}
          <a href="mailto:info@presolv360.com" target="_blank" rel="noreferrer">
            info@presolv360.com
          </a>{" "}
          indicating that you no longer wish to receive such messages, along
          with your e-mail address and the phone number of the mobile device
          receiving the messages.
        </p>
        <p>
          However, for the purpose of the Services availed through the platform,
          Rule 4 of Presolv360’s Dispute Resolution Rules set out the
          obligations of the user in respect of emails and platform use.{" "}
          <a
            href="https://presolv360.com/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>{" "}
          for more details.
        </p>

        <h3>Payment Terms</h3>
        <p>
          1. While availing any of the payment method(s) available on the
          platform, we will not assume liability for any loss caused to users
          due to payment issues arising out of the transactions.
        </p>
        <p>
          2. The payment for all Services available on the platform shall be
          made mandatorily in Indian National Rupees which are acceptable in the
          Republic of India. The platform will not facilitate transactions in
          other forms of currency with respect to the Services availed on the
          platform.
        </p>
        <p>
          3. You, as a buyer, understand that upon initiating a transaction, you
          are entering into a legally binding and enforceable contract with us
          to purchase the Services using the payment facility, and you shall pay
          the transaction price through your issuing bank to us using the
          payment facility.
        </p>
        <p>
          4. You represent and warrant that if you are purchasing something from
          us, that you will have the following options of payment:
          <ul>
            <li>Net Banking</li>
            <li>Debit Card</li>
            <li>Credit Card</li>
          </ul>
        </p>
        <p>
          5. You warrant that in case you are making the payment by credit card,
          (i) any credit information you supply is true and complete; (ii)
          charges incurred by you will be honored by your credit card company.
          In a credit card transaction, you must use your own credit card. We
          will not be liable for any credit card fraud. The liability of usage
          of a fraudulent card will be on person who indulges in such activity
          and the onus to ‘prove otherwise’ shall be exclusively on such person.
        </p>
        <p>
          6. We reserve the right to refuse to process transactions by buyers
          with a prior history of questionable charges including without
          limitation breach of any agreements by buyer with us or breach /
          violation of any law or any charges imposed by issuing bank or breach
          of any policy.
        </p>
        <p>
          7. All valid credit / debit card and other payment instruments are
          processed using a trusted payment gateway or appropriate payment
          system infrastructure, and the same will also be governed by the terms
          and conditions agreed to between the buyer and the respective issuing
          bank and payment instrument issuing company.
        </p>
        <p>
          8. All online bank transfers from valid bank accounts are processed
          using the payment gateway provided by the respective issuing bank
          which supports payment facility to provide these services to the
          users. All such online bank transfers on payment facility are also
          governed by the terms and conditions agreed to between buyer and the
          respective issuing bank.
        </p>
        <h3>Refund and Cancellation Policy</h3>
        <p>
          We strive to ensure that the Services you avail through the platform
          are to your full satisfaction and are the best in the industry.
          However, after placing an order, if you wish to cancel the order, you
          shall send an email to{" "}
          <a href="mailto:info@presolv360.com" target="_blank" rel="noreferrer">
            info@presolv360.com
          </a>{" "}
          along with the details of the order that needs to be cancelled and
          mention a valid reason for such cancellation. The order can be
          cancelled at any time, however, the fees paid for the Services are
          non-refundable. Refunds cannot be issued under any circumstances,
          unless the order is not processed after purchase or any of the
          Services are rejected outright by us.
        </p>
        <h3>Privacy Policy</h3>
        <p>
          Use of the platform is governed by our{" "}
          <a
            href="https://presolv360.com/privacy_policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Policy
          </a>{" "}
          . Please note that the Policy contains important terms, conditions,
          and declarations relating to any information, including sensitive
          personal data or information as defined in the Information Technology
          (Reasonable security practices and procedures and sensitive personal
          data or information) Rules, 2011, that constitute your consent to the
          manner in which such information and sensitive personal data and
          information may be accessed, used, exploited, and stored by the
          Company, and your use of the platform constitutes an acceptance of
          such terms, conditions, and declarations. We encourage you to read and
          review the terms of the Policy closely.
        </p>
        <h3>Limited Use</h3>
        <p>
          You agree and undertake not to reverse engineer, modify, copy,
          distribute, transmit, display, perform, reproduce, publish, license,
          create derivative works from, transfer, or sell any information
          obtained from the platform. Limited reproduction and copying of the
          content of the platform is permitted provided that our name is stated
          as the source and our prior written permission is sought. For the
          removal of doubt, it is clarified that unlimited or wholesale
          reproduction, copying of the content for commercial or non-commercial
          purposes and unwarranted modification of data and information within
          the content of the platform is not permitted.
        </p>

        <h3>User Conduct and Rules</h3>
        <p>
          You agree and undertake to use the platform and the services only to
          share messages and material that is proper. By way of example, and not
          as a limitation, you agree and undertake that when using any Services
          or the platform, you will not :
        </p>

        <p>
          1. Defame, abuse, harass, stalk, threaten or otherwise violate the
          legal rights of others;
        </p>
        <p>
          2. Publish, post, upload, distribute or disseminate any inappropriate,
          profane, defamatory, infringing, obscene, indecent or unlawful topic,
          name, material or information;
        </p>
        <p>3. Violate any code of conduct or other rules and guidelines;</p>
        <p>
          4. Violate any applicable laws or regulations for the time being in
          force in or outside India;
        </p>
        <p>
          5. Violate any of the terms of this Agreement, Policy or any other
          terms and conditions for the use of the platform contained elsewhere
          herein.
        </p>
        <h3>Prohibited Activity</h3>
        <p>
          You expressly agree that you are prohibited from hosting, displaying,
          uploading, modifying, publishing, transmitting, updating or sharing
          any information that{" "}
        </p>
        <p>
          1.Belongs to another person and to which the user does not have any
          right to;
        </p>
        <p>
          2.Is grossly harmful, harassing, blasphemous defamatory, obscene,
          pornographic, paedophilic, libellous, invasive of another's privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever;
        </p>
        <p>3.Harm minors in any way;</p>
        <p>
          4.Infringes any patent, trademark, copyright or other proprietary
          rights;
        </p>
        <p>5.Violates any law for the time being in force;</p>
        <p>
          6.Deceives or misleads the addressee about the origin of such messages
          or communicates any information which is grossly offensive or menacing
          in nature;
        </p>
        <p>7.Impersonate another person</p>
        <p>
          8.Contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource;
        </p>
        <p>
          9.Threatens the unity, integrity, defense, security or sovereignty of
          India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognizable offence or
          prevents investigation of any offence or is insulting any other
          nation.
        </p>
        <h3>Warranty and Representation</h3>
        <p>
          You hereby warrant that you are capable of agreeing to the Terms and
          that this Agreement is not in contravention to any rights of any party
          with whom you have any agreements, at any time prior to your
          acceptance of the Terms.
        </p>
        <p>
          You agree to provide and continue to provide all details about
          yourself as may be required by Presolv360, including but not
          restricted to Personal Information, and state that all details,
          information and facts represented to us are true, complete and
          accurate.
        </p>
        <h3>No Agency or Partnership</h3>
        <p>
          No agency, partnership, joint venture, or employment is created as a
          result of this Agreement or your use of any part of the platform.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          The platform including any content or information contained therein or
          any website linked to the platform, or any product or service licensed
          or purchased through the platform, is provided on an "as is" basis and
          without warranties of any kind, either express or implied, including,
          but not limited to warranties of title or non-infringement or
          warranties of merchantability and fitness for a particular purpose,
          other than those warranties which are implied by and incapable of
          exclusion, restriction or modification under the laws applicable.
        </p>
        <p>
          We do not warrant that your access to the platform will be
          uninterrupted or error-free, that defects will be corrected, or that
          the platform or the server on which the platform is hosted is free of
          viruses or other harmful components. Access to and use of the platform
          and the information provided herein is at your own risk and we do not
          undertake any accountability for any irregularities, viruses or damage
          to any computer or device that results from accessing, availing or
          downloading of any information from the platform. We do not warrant or
          make any representations regarding the use of or any consequences
          resulting from the use of any product and / or service purchased in
          terms of its compatibility, correctness, accuracy, and reliability or
          otherwise. You assume total responsibility and risk for your use of
          the platform and all platform-related services.
        </p>
        <p>
          You agree that we and our directors, officers, employees, agents,
          sponsors, consultants, business partners or other representatives
          shall not be responsible or liable for any direct, indirect,
          incidental, consequential, special, exemplary, punitive or any other
          damages (including without limitation loss of profits, loss or
          corruption of data, loss of goodwill, work stoppage, computer failure
          or malfunction, or interruption of business) under any contract,
          negligence, strict liability or any other theory arising out of or
          relating in any way with the use of the platform or in reliance of the
          information available on the platform, platform-related services, or
          any products or services offered or sold or displayed on the platform.
        </p>
        <p>
          If the foregoing limitation is held to be unenforceable, our maximum
          liability to you shall not exceed the amount of fees paid by you for
          the products or services that you have availed through the platform.
        </p>
        <p>
          The possibility exists that the platform could include inaccuracies or
          errors. Additionally, the possibility exists that unauthorized
          additions, deletions or alterations could be made by third parties to
          the platform. Although we attempt to ensure the highest level of
          integrity, correctness and authenticity of the platform, we make no
          guarantees whatsoever as to its completeness, correctness or accuracy.
          In the event that any inaccuracy arises, please inform us at{" "}
          <a href="mailto:info@presolv360.com" target="_blank" rel="noreferrer">
            info@presolv360.com
          </a>{" "}
          so that it can be corrected.
        </p>
        <h3>Usage Terms</h3>
        <p>
          The information, materials, Services available on the platform may
          inadvertently include inaccuracies, typographical errors, or outdated
          information. We are not responsible for and shall not be bound to
          honor typographical or pricing errors on the platform. We reserve the
          right to refuse or cancel orders at any time, including but not
          limited to the orders that contain incorrect prices or product
          descriptions, orders in which we believe the buyer has violated
          applicable laws or the terms of this Agreement, orders that we believe
          are harmful to us or orders that we believe are fraudulent or based on
          illegal, fraudulent or deceitful use / furnishing of information or
          based on false information.
        </p>
        <h3>Intellectual Property Rights</h3>
        <p>
          1. Unless otherwise indicated or anything contained to the contrary or
          any proprietary material owned by a third party and so expressly
          mentioned, we own all intellectual property rights to and into the
          platform, including, without limitation, any and all rights, title and
          interest in and to copyright, related rights, patents, utility models,
          trademarks, trade names, designs, know-how, trade secrets and
          inventions (whether patentable or not), goodwill, source code, meta
          tags, databases, text, content, graphics, icons, and hyperlinks. You
          acknowledge and agree that you shall not use, reproduce or distribute
          any content from the platform belonging to platform without obtaining
          prior authorization from us.
        </p>
        <p>
          2. Notwithstanding the foregoing, it is expressly clarified that you
          will retain ownership and shall solely be responsible for any content
          that you provide, including any text, data, personal information,
          images, photographs, or any other material which you may upload or
          transmit.
        </p>
        <h3>Copyrighted Material for Limited Use</h3>
        <p>
          This platform contains graphics, photographs, images, document
          layouts, artwork, text, fonts, software tools, and other information
          (referred to herein as “Content”). This platform and all Content are
          the copyrighted property of the Company, and / or its subsidiaries, or
          the copyrighted property of parties from whom we have licensed such
          property. All rights in this platform and its Content are reserved
          worldwide. It is strictly prohibited to retain, copy, distribute,
          publish, or use any portion of the Content except as expressly allowed
          in herein. We reserve the right to add to, delete from, or modify any
          part of Content at any time without prior notice.
        </p>
        <h3>Links to Third Party Sites</h3>
        <p>
          Third party content may appear on the platform or may be accessible
          via links. We are not responsible for and assume no liability for any
          mistakes, misstatements of law, defamation, slander, libel, omissions,
          falsehood, obscenity or profanity in the statements, opinions,
          representations or any other form of information contained in any
          third-party content appearing on the platform. You understand that the
          information and opinions in the third-party content is neither
          endorsed by nor does it reflect our belief.
        </p>
        <h3>No Guarantee</h3>
        <p>
          You acknowledge and agree that nothing mentioned on the platform shall
          be construed as a guarantee or promise of the results or effectiveness
          of the Services rendered. We assure you that we will provide the
          Services in a professional manner, in good faith and in accordance
          with all the relevant national, state and local laws. Nonetheless, we
          do not promise or guarantee any results.
        </p>
        <h3>Indemnity</h3>
        <p>
          You agree to indemnify, defend and hold harmless us, from and against
          any and all losses, liabilities, claims, damages, costs and expenses
          (including legal fees and disbursements in connection therewith and
          interest chargeable thereon) asserted against or incurred by you, that
          arise out of, result from, or may be payable by virtue of, any breach
          or non-performance of any representation, warranty, covenant or
          agreement made or obligation to be performed by you pursuant to the
          terms herein.
        </p>
        <h3>Notices</h3>
        <p>
          By using the platform, you consent to electronically receive from us
          any communications including but not limited to notice, instructions,
          communication, notification, order, award, agreements, legally
          required disclosures or other information.
        </p>
        <h3>Breach of Terms</h3>
        <p>
          We reserve the right to investigate complaints or reported violations
          of this Agreement and to take any action we deem appropriate including
          but not limited to reporting any suspected unlawful activity to law
          enforcement officials, regulators, or other third parties and
          disclosing any information necessary or appropriate to such persons or
          entities, subject to confidentiality obligations of the Company,
          relating to e-mail addresses, usage history, posted materials, IP
          addresses and traffic information. We reserve our right to seek all
          remedies available at law and in equity for violations of the terms of
          this Agreement, including but not limited to the right to block access
          from a particular internet address to the platform and Services.
        </p>
        <h3>Confidentiality</h3>
        <p>
          We make every effort to maintain the confidentiality of Personal
          Information, Content or any other information submitted by you to our
          system and not disclose it to any third party. We have implemented
          appropriate managerial, technical, operational and physical procedures
          to safeguard your Personal Information, Content and any other
          information against loss, misuse or theft, as well as against
          destruction, alteration, disclosure and unauthorized access. For more
          information, refer to{" "}
          <a
            href="https://presolv360.com/odr_standard"
            target="_blank"
            rel="noopener noreferrer"
          >
            Standards
          </a>{" "}
          adopted by Presolv360.
        </p>
        <h3>Termination of Access</h3>
        <p>
          The terms of this Agreement are effective until terminated. You agree
          that we may terminate your access to the platform at any time for any
          reason without prior notice or liability.
        </p>
        <h3>Entire Agreement</h3>
        <p>
          The Terms, the Policy and any other relevant terms and conditions,
          policies and notices which may be applicable to a specific section or
          module of this platform will together constitute the entire and whole
          agreement between the you and us, and together are intended as a
          complete and exclusive statement of the terms of this Agreement. This
          Agreement shall supersede all other communications between you and us
          with respect to the subject matter hereof and supersedes and replaces
          all prior or contemporaneous understandings or agreements, written or
          oral, regarding such subject matter. If at any time you find the terms
          of this Agreement unacceptable or if you do not agree with them,
          kindly refrain from using the platform.
        </p>
        <h3>Severability</h3>
        <p>
          If at any time any provision of this Agreement is or becomes, or is
          adjudicated by any court of competent jurisdiction or public authority
          to be illegal, invalid or unenforceable in any respect under the law
          of any jurisdiction, this shall not affect or impair the legality,
          validity or enforceability of any other provision of this Agreement.
        </p>
        <h3>Waiver</h3>
        <p>
          We will not be considered to have waived any of the rights or remedies
          described in this Agreement unless the waiver is in writing and signed
          by us. No delay or omission by us in exercising our rights or remedies
          will impair or be construed as a waiver. Any single or partial
          exercise of a right or remedy will not preclude further exercise of
          any other right or remedy. Our failure to enforce the strict
          performance of any provision of this Agreement will not constitute a
          waiver of our right to subsequently enforce such provision or any
          other provisions of this Agreement.
        </p>
        <h3>Force Majeure</h3>
        <p>
          Neither you or us shall be liable to the other if, and to the extent,
          that the performance or delay in performance of any of its obligations
          under this Agreement is prevented, restricted, delayed or interfered
          with, due to circumstances beyond the reasonable control of such
          party, including but not limited to, Government legislations, fires,
          floods, explosions, epidemics, accidents, acts of God, wars, riots,
          strikes, lockouts, or other concerted acts of workmen, acts of
          Government and / or shortages of materials, hacking, unanticipated
          technological or natural interference or intrusion, loss or damage to
          satellites, loss of satellite linkage or any other data communications
          linkage, loss of connectivity or any other irresistible force or
          compulsion.
        </p>
        <h3>Dispute Resolution</h3>
        <p>
          In the event that any disputes, differences, claims and questions
          whatsoever arising out of or in connection with or incidental to or
          touching this Agreement or the construction or application thereof or
          any clauses or thing herein contained or in respect of any account and
          the duties, responsibilities and obligations of either party hereunder
          or as to any act or omission of any party or as to any other matter in
          anywise relating to these presents or the rights, duties and
          liabilities of either party under these presents shall be resolved and
          settled by Arbitration, and administered electronically, in accordance
          with the Arbitration and Conciliation Act, 1996 or any statutory
          modification or re-enactment thereof for the time being in force. The
          Parties agree that the aforesaid proceedings shall be carried out by a
          sole arbitrator. The juridical seat of the arbitration shall be at
          Mumbai, India. The language of the arbitration shall be English. The
          law governing the arbitration proceedings shall be Indian law. Each
          Party shall bear its own cost of arbitration. In the event the
          arbitration proceedings cannot be administered electronically, the
          proceedings shall be conducted physically and the venue of the
          proceedings shall be Mumbai, India.
        </p>
        <h3>Governing Law and Jurisdiction</h3>
        <p>
          This Agreement shall be governed by and construed in accordance with
          law prevalent in India. You hereby agree that the courts having
          jurisdiction in Mumbai shall have exclusive jurisdiction to hear and
          decide any suit, action or proceedings and to resolve any disputes,
          arising in connection with this Agreement.
        </p>
        <h3>Grievance Officer And Redressal Mechanism</h3>
        <p>
          Any complaints, abuse or concerns with regards to content and / or
          comment or breach of these Terms shall be immediately informed to Mr.
          Bhaven Shah ("Grievance Officer") via email at{" "}
          <a href="mailto:bhaven@presolv360.com">bhaven@presolv360.com</a> or in
          writing at the following address to:
        </p>

        <p>
          Addressed To: Mr. Bhaven Shah Designation: Grievance Officer Address:
          1st Floor, Esperanca Building, Shahid Bhagat Singh Road, Colaba,
          Mumbai-400001.
        </p>

        <p>
          The Grievance Officer shall acknowledge the receipt of the complaints
          within a period of 24 (twenty-four) hours from the receipt of the
          same, and will undertake best endeavours to redress the grievances of
          the User expeditiously, but in any case, grievances will be addressed
          within 15 (fifteen) days from the date of receipt of the grievance.
        </p>

        <p>
          A grievance will be considered as disposed-off and closed in any of
          the following instances, namely:
        </p>

        <ul>
          <li>
            (a) where the complainant has communicated its acceptance of the
            response of the Grievance Officer / any other person associated with
            the Company; or
          </li>
          <li>
            (b) where the complainant has not responded within thirty days of
            the receipt of the written response and has not raised any grievance
            or complaint in respect of the same subject.
          </li>
        </ul>

        <h3>Section Headings</h3>
        <p>
          The section headings in this Agreement are for convenience only and
          have no legal or contractual effect.
        </p>
        <h3>Contact Information</h3>
        <p>
          If you wish to contact us for any reason regarding the terms of this
          Agreement, kindly e-mail it to us at the following address:{" "}
          <a href="mailto:info@presolv360.com" target="_blank" rel="noreferrer">
            info@presolv360.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}
