import { useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import { ArrowUpRight, CircleChevronLeft } from "lucide-react";
import { ToastContainer } from "react-toastify";
import "../../assets/styles/templatelibrary.css";

export default function TemplateLibrary() {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");

  // Navigation items
  const navItems = useMemo(
    () => [
      { title: "Claimant", to: "/template-library/claimant" },
      { title: "Arbitrator", to: "/template-library/arbitrator" },
      { title: "Respondent", to: "/template-library/respondent" },
    ],
    []
  );

  return (
    <div className="container-template">
      <ToastContainer />

      {/* Back Button */}
      <div className="back-button" onClick={() => navigate(-1)}>
        <CircleChevronLeft className="back-icon" />
      </div>

      <h1 className="heading_1">Find your template as</h1>

      {/* Navigation Grid */}
      <div className="grid_1">
        {navItems.map((item) => (
          <button
            key={item.to}
            onClick={() => navigate(item.to)} // 🔥 Navigates to new screen
            className="card_1"
          >
            <div className="link_1">
              {item.title}
              <ArrowUpRight className="card_1-icon" />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
