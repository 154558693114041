import "./Extra.css";

import React from "react";
import { Helmet } from "react-helmet";

import AnimatedBox from "../../components/AnimatedBox";

export default function Standard() {
  return (
    <div>
      <Helmet>
        <title>
          Presolv360 | Standards | Online Arbitration | Money Recovery
        </title>
        <meta
          name="description"
          content="Learn from global leaders how online dispute resolution can transform and empower your disputes with expert resolution in a digital era."
        />
        <meta name="keywords" content="dispute resolution global" />
      </Helmet>
      <div className="firstBreak extraPage">
        <section className="landing_wrap">
          {window.innerWidth > 600 ? (
            <h1 style={{ padding: "0 1.5em" }}>
              Online Dispute Resolution standards
            </h1>
          ) : (
            <h1>Online Dispute Resolution standards</h1>
          )}
          <AnimatedBox />
        </section>
      </div>
      <div className="extra_section_wrap">
        <h3>Introduction</h3>
        <p>
          While online dispute resolution (“ODR”) standards, principles and
          notes are of a non-binding nature and relate to online dispute
          resolution systems in general, they provide boilerplate parameters
          while using information technology for resolving disputes online.
        </p>
        <p>
          For the purpose of Presolv360’s ODR platform (“Platform”), we have
          referred to:
        </p>
        <li className="boldBullet">
          {" "}
          Technical Notes on Online Dispute Resolution of the United Nation
          Commission on International Trade Law (“UNCITRAL”) :{" "}
        </li>
        <p>
          UNCITRAL was established by the United Nations to promote the
          progressive harmonization and unification of international trade law.
          These notes are a non-binding descriptive document reflecting elements
          of an online dispute resolution process. It recommends that all
          stakeholders should use these notes in designing and implementing
          online dispute resolution systems for resolving disputes.
        </p>

        <li className="boldBullet">
          {" "}
          Ethical Principles for Online Dispute Resolution (Project of the
          National Center for Technology and Dispute Resolution) :{" "}
        </li>
        <p>
          These principles are designed to enhance the quality, effectiveness,
          and scope of dispute resolution processes with technological
          components.
        </p>

        <li className="boldBullet">
          {" "}
          International Council for Online Dispute Resolution (“ICODR”)
          Standards :{" "}
        </li>
        <p>
          The ICODR is a response to the recent, rapid and widespread adoption
          of technology-supported systems for dispute resolution that have been
          taking place in China, India, England, Canada, the U.S. and elsewhere,
          both in the private as well as the public sector. These standards
          provide a touchstone for best practices, rules, qualifications, and
          certification efforts for online dispute resolution processes and
          practices.
        </p>

        <li className="boldBullet">
          Online Dispute Resolution Standards of Practice by the Internet
          Corporation for Assigned Names and Numbers (“ICANN”) :
        </li>
        <p>
          These standards of practice are recommended by the Advisory Committee
          of the National Centre for Technology and Dispute. The standards are
          based on the current literature and research in the field of ODR and
          are offered as guidelines for practice across the spectrum of ODR.
        </p>

        <h3>Standards</h3>
        <li className="boldBullet"> Accessibility</li>
        <p>
          The Platform is a user-friendly web-based platform accessible from
          mobile and desk-based devices providing greater outreach regardless of
          geographical boundaries. It has a simple and intuitive user interface
          and provides a guidebook and video tutorials for smooth participation
          and navigation.
        </p>

        <li className="boldBullet"> Accountability</li>
        <p>
          The Platform through its Dispute Resolution Rules (“Rules”) remains
          accountable to the community it serves and functions within the legal
          framework prevalent in India, for the time being in force.
        </p>

        <li className="boldBullet"> Affordability</li>
        <p>
          Resolving disputes on the Platform is an economical alternative to
          litigation and in-person dispute resolution. With its unique approach,
          the Platform facilitates zero-cost and expeditious dispute resolution.
        </p>

        <li className="boldBullet">Competence </li>
        <p>
          The systems and processes are built and designed keeping in mind
          effective dispute resolution. The Platform providers and practitioners
          are competent in fields including but not limited to dispute
          resolution, law, technology, language and culture.
        </p>
        <li className="boldBullet">Confidentiality </li>
        <p>
          While the Platform technology and process implementation have been
          designed and developed keeping in mind confidentiality obligations
          applicable to dispute resolution, the Rules bind the parties,
          practitioners and providers to confidentiality. The Privacy Policy
          sets out what data will be collected and how it will be used.
        </p>
        <li className="boldBullet">Consensual Resolution </li>
        <p>
          The Platform, Rules and processes are designed to encourage parties,
          wherever appropriate, to resolve disputes using consensual processes.
        </p>
        <li className="boldBullet">Empowerment </li>
        <p>
          The Platform and Rules are centered around the idea of empowering
          people to resolve their own disputes. The systems and processes are
          developed and designed in a manner that increases access to justice,
          enhancement of choices and effective decision-making opportunities.
        </p>
        <li className="boldBullet">Equality </li>
        <p>
          The Platform and processes are designed in a manner that ensures equal
          treatment of parties and follows the principles of natural justice and
          equity while keeping in mind quick, economical, effective and
          efficient dispute resolution.
        </p>
        <li className="boldBullet">Expertise </li>
        <p>
          {" "}
          While the Platform providers possess relevant understanding of
          technology, design and law, the practitioners are subject to a
          comprehensive selection and empanelment process keeping in mind their
          qualification, competence, knowledge and expertise. The practitioners
          are required to complete all requisite trainings and certifications as
          may be prescribed by Presolv360 and are also recommended to
          periodically engage in various forms of continued education to refine
          and improve their knowledge and skills.
        </p>
        <li className="boldBullet">Fairness, Impartiality and Neutrality </li>
        <p>
          The Platform together with the Rules ensure actual and perceived
          fairness, impartiality and neutrality of the systems, processes and
          practitioners throughout the dispute resolution process.
        </p>
        <li className="boldBullet">Honesty </li>
        <p>
          Information, data and documents, to the extent possible and permitted
          by law, is gathered, managed and presented on an ‘as is’ basis to
          ensure it is not misrepresented or presented out of context. Ex-parte
          communications, to the extent possible, are prohibited, and the
          practitioners are required to make appropriate declarations and
          disclosures before and during the dispute resolution process.
        </p>
        <li className="boldBullet">Informed Participation</li>
        <p>
          The Rules, systems and processes ensure explicit disclosure to,
          competence of, understanding and acceptance by participants about the
          risks and benefits of resolving disputes through the Platform.
        </p>
        <li className="boldBullet">Innovation</li>
        <p>
          Presolv360 prides itself on constantly innovating and improving
          service delivery and technology while keeping in mind the requirements
          of the community and the law.
        </p>
        <li className="boldBullet">Legal Obligation</li>
        <p>
          The systems and processes abide by and uphold the laws prevalent in
          India, to the extent applicable.
        </p>
        <li className="boldBullet">Security</li>
        <p>
          All reasonable efforts are made to keep data and communication between
          parties and all persons involved in the dispute resolution process
          secure and confidential. For more information, refer Part III of this
          document.
        </p>
        <li className="boldBullet">Transparency</li>
        <p>
          The Platform, Rules and processes explicitly set out the risks and
          benefits of participation, the form and enforceability of dispute
          resolution processes and outcomes; the identities, affiliations,
          obligations and conflicts of interest of the parties and
          practitioners; and the security efforts, confidentiality and privacy
          policy involved.
        </p>
        <h3>Security</h3>
        <p>
          Presolv360, on the Platform, has implemented appropriate safeguards to
          prevent unauthorized access to any party’s information or documents
          and to maintain data security. Presolv360 employs encryption
          technology for protecting sensitive information. However, electronic
          mails or other electronic means of communication may not be encrypted
          and it is recommended that parties do not communicate any confidential
          information through the aforementioned means. Presolv360 follows
          generally accepted industry standards to protect the information and
          documents submitted on the Presolv360 Platform. However, no method of
          electronic transmission or storage is 100% secure. While Presolv360
          strives to use commercially acceptable means to protect the
          information and documents of the parties, Presolv360 cannot guarantee
          its absolute security and assumes no liability or responsibility for
          disclosure of any party’s information due to errors in transmission,
          unauthorized third-party access, or other causes beyond Presolv360’s
          control. The parties play an important role in keeping its information
          secure. Parties should not share its account information with any
          third party. Presolv360 shall not be liable for any loss or damage
          caused by the use of third-party applications by the parties.
        </p>
      </div>
    </div>
  );
}
