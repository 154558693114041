import React from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

import Image6 from "../assets/Images/Aman Shangvi.png";
import Image4 from "../assets/Images/Bhaven Shah.png";
import FounderIMage from "../assets/Images/Founding Team 1.png";
import Image3 from "../assets/Images/Justice Kannan New.png";
import Pradeep from "../assets/Images/Justice Pradeep Nandrajog.png";
import Image2 from "../assets/Images/Justice Sri Krishna New2.png";
import Image1 from "../assets/Images/Justice U.U. Lalit New.png";
import Rajni from "../assets/Images/Late Ms Rajani Iyer.png";
import Ankit from "../assets/Images/Mr Ankit Sahni.png";
import Ashok from "../assets/Images/Mr Ashok Barat.png";
import Raman from "../assets/Images/Mr Raman Aggarwal.png";
import Srinath from "../assets/Images/Mr Srinath Sridharan.png";
import Tanu from "../assets/Images/Ms Tanu Mehta.png";
import Image5 from "../assets/Images/Namita Shah.png";
import AnimatedBox from "../components/AnimatedBox";
import FlipCard from "../components/flipCard/FlipCard";
import MileStoneMobile from "../components/MileStone_mobile";
import MobileCorevalue from "../components/Mobile_corevalue";

export default function AboutPage() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dotsClass: "sliderDots",
    customPaging: (i) => <div>{i + 1}</div>,
  };
  const FlipCardData = [
    {
      tittle: "Mission driven",
      description:
        "We will passionately apply ourselves to serving our stakeholders, knowing that if we create value for them, value will flow to our company",
    },
    {
      tittle: "Extreme ownership",
      description:
        "We think and act like owners. Our focus is on buliding long-term value, not scoring short-term marks",
    },
    {
      tittle: "Boldness",
      description:
        "We want to revolutionize. Make a difference. We would rather fail at something meaningful than succeed at something trivial",
    },
    {
      tittle: "Creating our own luck",
      description: "The best way to make progress is to act",
    },
    {
      tittle: "Economical",
      description:
        "Efficiency is important, and we consider both the costs and benefits of our actions",
    },
    {
      tittle: "Team oriented",
      description:
        'In working together, we maintain a low ego, make everyone feel welcome, and trust one another. We empower our teammates to be at their best. We believe in the principle of "support each other to grow together"',
    },
    {
      tittle: "Challenged but not overwhelmed",
      description:
        "We are curious people and learning is our second name. Growth happens outside our comfort zone. We achieve our potential through consistent, manageable growth",
    },
    {
      tittle: "Integrity",
      description:
        "We do the right thing. When we make mistakes, we own and correct them. Everyone can count on us to act according to our values, beliefs, and principles we state we hold. This trust must never be broken",
    },
  ];

  return (
    <div className="aboutPage">
      <Helmet>
        <title>
          Presolv360 | About Us | Online Dispute Resolution | Legaltech
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="msapplication-TileImage"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />

        <meta
          name="description"
          content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally."
        />

        <meta
          name="keywords"
          content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India"
        />

        <meta
          property="og:keywords"
          content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India"
        />
        <meta
          property="og:description"
          content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally."
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />
        <meta
          property="og:image:secure_url"
          content="%PUBLIC_URL%/Thumbnail2_1280_720.png"
        />
        <meta property="og:url" content="https://www.presolv360.com" />
        <meta
          property="og:title"
          content=" Presolv360 | Dispute resolution made easy"
        />
        <meta property="og:type" content="article" />
        <link rel="canonical" href="https://presolv360.com/about-us" />
      </Helmet>
      <div className="firstBreak">
        <section className="landing_wrap">
          <h1 data-aos="fade-right" data-aos-duration="1500">
            “Son, your father started this case and <br></br> your child will
            conclude it”
          </h1>
          {window.innerWidth > 600 ? (
            <h3 data-aos="fade-left" data-aos-duration="1500">
              This remark by a Supreme Court judge was the <br></br> eureka
              moment which led to the inception of <br></br>
              Presolv<span style={{ color: "var(--color_2)" }}>360</span>
            </h3>
          ) : (
            <h3 data-aos="fade-left" data-aos-duration="1500">
              This remark by a Supreme Court judge was the eureka moment which
              led to the inception of
              <br></br>
              Presolv<span style={{ color: "var(--color_2)" }}>360</span>
            </h3>
          )}
          <AnimatedBox />
        </section>

        <div className="section_heading">
          <h2
            className="blue_heading"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            Founders' Story
          </h2>
        </div>
        <section className="aboutPageSection">
          <div className="leftSection">
            <img
              src={FounderIMage}
              alt="FounderiMage"
              data-aos="fade-left"
              data-aos-duration="1000"
            />
          </div>
          <div className="rightSection">
            <p data-aos="fade-up" data-aos-duration="1500">
              Heaps of papers, complex procedures, endless causelists, and the
              echoes of ‘tareekh pe tareekh’ (adjournments) – these are
              commonplace sights and sounds of Indian courtrooms. Aman, Bhaven,
              and Namita witnessed these in multiple courtrooms again and again.
              It was a two-edged sword – while they had to live through the
              burdens of these inefficiencies, it also inspired them to create
              change. This led to the birth of Presolv360.
            </p>
            <p data-aos="fade-up" data-aos-duration="1500">
              The tipping point was an incident in one such courtroom, where the
              judge told Bhaven, “Son, your father filed this case, and it is
              your child that will continue to fight it.” In that moment,
              despair turned into determination, and the three friends set out
              on a journey to change the narrative of dispute resolution.
            </p>
            <p data-aos="fade-up" data-aos-duration="1500">
              Presolv360 thus started with the vision of becoming a global
              leader in resolving disputes using technology. The heart of the
              founders’ vision for the company is using a blend of the latest
              technologies and human ingenuity to craft solutions that
              revolutionize the global justice landscape.
            </p>
          </div>
        </section>
      </div>

      {/**************Core Value Section ************* */}

      <div className="section_heading">
        <h2
          className="blue_heading"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          OUR CORE VALUES
        </h2>
      </div>

      {window.innerWidth > 600 ? (
        <section className="aboutPageSection">
          <div className="core_value_wrap">
            {FlipCardData.map((data) => {
              return <FlipCard imageData={data} />;
            })}
          </div>
        </section>
      ) : (
        <>
          <MobileCorevalue />
        </>
      )}

      {/**************Mile Stone Section ************* */}
      <section
        className="milestonesection"
        style={{
          backgroundColor: "var(--color_1)",
          padding: "2em 1em 4em 1em",
        }}
      >
        <div className="section_heading">
          <h2
            className="blue_heading"
            style={{ color: "#fff" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            MILESTONES
          </h2>
        </div>

        {window.innerWidth > 600 ? (
          <>
            <svg
              width="40"
              height="81"
              viewBox="0 0 40 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="20" fill="#FBB04C" />
              <line
                x1="20"
                y1="20"
                x2="20"
                y2="81"
                stroke="#FBB04C"
                stroke-width="4"
              />
            </svg>
            <div
              className="milestoneCard"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2>2017</h2>
              <hr></hr>
              <p>
                Blending research, innovation, tech, and empathy, Presolv360 was
                founded to make dispute resolution simple and user-centric
              </p>
            </div>
            {/* <svg
              width="40"
              height="96"
              viewBox="0 0 40 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="46" r="20" fill="#FBB04C" />
              <line
                x1="20"
                y1="-8.74228e-08"
                x2="20"
                y2="96"
                stroke="#FBB04C"
                stroke-width="4"
              />
            </svg> */}
            {/* <div
              className="milestoneCard"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2>2020</h2>
              <hr></hr>
              <p>
                Included as an ADR/ODR institution in the notification issued by
                Department of Legal Affairs, Ministry of Law and Justice
              </p>
            </div> */}
            <svg
              width="40"
              height="96"
              viewBox="0 0 40 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="46" r="20" fill="#FBB04C" />
              <line
                x1="20"
                y1="-8.74228e-08"
                x2="20"
                y2="96"
                stroke="#FBB04C"
                stroke-width="4"
              />
            </svg>
            <div
              className="milestoneCard"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 className="no_wrap">2020-21</h2>
              <hr></hr>
              <p>
                Launched proprietary mediation and arbitration modules and
                empanelled as a Mediation Institute by the Bombay High Court
              </p>
            </div>
            <svg
              width="40"
              height="96"
              viewBox="0 0 40 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="46" r="20" fill="#FBB04C" />
              <line
                x1="20"
                y1="-8.74228e-08"
                x2="20"
                y2="96"
                stroke="#FBB04C"
                stroke-width="4"
              />
            </svg>
            <div
              className="milestoneCard"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 className="no_wrap">2022-23</h2>
              <hr></hr>
              <p>
                Incubated by Cyril Amarchand Mangaldas, empanelled by 8 Courts
                and integrated into the securities market ecosystem as per SEBI
                Master Circular on ODR
              </p>
            </div>
            {/* <svg
              width="40"
              height="96"
              viewBox="0 0 40 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="46" r="20" fill="#FBB04C" />
              <line
                x1="20"
                y1="-8.74228e-08"
                x2="20"
                y2="96"
                stroke="#FBB04C"
                stroke-width="4"
              />
            </svg>
            <div
              className="milestoneCard"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2>2023</h2>
              <hr></hr>
              <p>
                18,38,456 negotiation workflows triggered and 1,65,791 disputes
                administered registered by 70+ enterprises and 5 courts
              </p>
            </div> */}
          </>
        ) : (
          <>
            <MileStoneMobile />
          </>
        )}
      </section>

      <div className="section_heading" style={{ paddingTop: "3em" }}>
        <h2
          className="blue_heading"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          ADVISORY COUNCIL
        </h2>
      </div>
      {window.innerWidth > 600 ? (
        <div className="guide_body_wrap council">
          <div>
            <div className="card">
              {" "}
              <img src={Image1} alt="Justice U.U. Lalit" />
              <div className="info">
                <p>
                  "While technology is changing the dispute resolution
                  ecosystem, it must be backed by strong institutions running
                  ethically and following due process. I am delighted to lend my
                  support to an institution that is at the forefront of this"
                </p>
              </div>
            </div>
            <h3
              className="greyColor_text"
              style={{
                textAlign: "center",
                paddingTop: "1em",
                fontSize: "2vw",
              }}
            >
              Justice U. U. Lalit
            </h3>
            <p
              className="greyColor_text"
              style={{
                textAlign: "center",
                paddingTop: ".5em",
                fontSize: "1.2vw",
              }}
            >
              Former Chief Justice of india
            </p>
          </div>

          <div>
            <div className="card">
              {" "}
              <img src={Image2} alt="Justice B.N. Srikrishna" />
              <div className="info">
                <p>
                  "For India to become a hub of ADR, as is the ambition these
                  days, it must focus on two things - institutions and ODR.
                  Presolv360 is working at the intersection of both."
                </p>
              </div>
            </div>
            <h3
              className="greyColor_text"
              style={{
                textAlign: "center",
                paddingTop: "1em",
                fontSize: "2vw",
              }}
            >
              Justice B. N. Srikrishna
            </h3>
            <p
              className="greyColor_text"
              style={{
                textAlign: "center",
                paddingTop: ".5em",
                fontSize: "1.2vw",
              }}
            >
              Judge (Retd.) Supreme Court of India
            </p>
          </div>

          <div>
            <div className="card">
              {" "}
              <img src={Image3} alt="Justice K. Kannan" />
              <div className="info">
                <p>
                  "To see this organisation grow from an idea disrupting the
                  legal-tech ecosystem to now seeking to become a leading ADR
                  institution is heartening. ODR institutions must not be looked
                  at any differently from the likes of SIAC or closer home,
                  Delhi International Arbitration Centre & others."
                </p>
              </div>
            </div>
            <h3
              className="greyColor_text"
              style={{
                textAlign: "center",
                paddingTop: "1em",
                fontSize: "2vw",
              }}
            >
              Justice K. Kannan
            </h3>
            <p
              className="greyColor_text"
              style={{
                textAlign: "center",
                paddingTop: ".5em",
                fontSize: "1.2vw",
              }}
            >
              Judge (Retd.) Punjab & Haryana High Court
            </p>
          </div>

          {/* <div className='guide_card'>
                 <img  src={Image1} alt='Justice U.U. Lalit'/>
                 <h3 className='greyColor_text'>Justice U. U. Lalit</h3>
                 <p className='greyColor_text'>Former Chief Justice of india</p>
               </div>
               <div className='guide_card'>
               <img  src={Image2} alt='Justice B.N. Srikrishna'/>
                 <h3 className='greyColor_text'>Justice B. N. Srikrishna</h3>
                 <p className='greyColor_text'>Judge (Retd.) Supreme Court of India</p>
               </div>
               <div className='guide_card'>
               <img  src={Image3} alt='Justice K. Kannan'/>
                 <h3 className='greyColor_text'>Justice K. Kannan</h3>
                 <p className='greyColor_text'>Judge (Retd.) Punjab & Haryana High Court</p>
               </div> */}
        </div>
      ) : (
        <Slider {...settings}>
          <div>
            <div className="guide_card">
              <img src={Image1} alt="Justice U.U. Lalit" />
              <h3 className="greyColor_text">Justice U. U. Lalit</h3>
              <p className="greyColor_text">Former Chief Justice of india</p>
            </div>
          </div>
          <div>
            <div className="guide_card">
              <img src={Image2} alt="Justice B.N. Srikrishna" />
              <h3 className="greyColor_text">Justice B. N. Srikrishna</h3>
              <p className="greyColor_text">
                Judge (Retd.) Supreme Court of India
              </p>
            </div>
          </div>
          <div>
            {" "}
            <div className="guide_card">
              <img src={Image3} alt="Justice K. Kannan" />
              <h3 className="greyColor_text">Justice K. Kannan</h3>
              <p className="greyColor_text">
                Judge (Retd.) Punjab & Haryana High Court
              </p>
            </div>
          </div>
        </Slider>
      )}
      <div className="section_heading" style={{ padding: "1em 0 1em 0" }}>
        <h2
          className="blue_heading"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          ADVISORY BOARD
        </h2>
      </div>

      {window.innerWidth > 600 ? (
        <div className="guide_body_wrap advisoryBord">
          <div className="guide_card">
            <img src={Pradeep} alt="Justice Pradeep Nandrajog" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Justice Pradeep Nandrajog
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Chief Justice (Retd.) Bombay High Court
            </p>
          </div>
          <div className="guide_card">
            <img src={Srinath} alt="Mr Srinath Sridharan" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Mr. Srinath Sridharan
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Corporate Leader, CXO Coach
            </p>
          </div>

          <div className="guide_card">
            <img src={Ashok} alt="Mr Ashok Barat" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Mr. Ashok Barat
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Former MD & CEO, Forbes & Company Ltd
            </p>
          </div>
        </div>
      ) : (
        <Slider {...settings}>
          <div>
            <div className="guide_card">
              <img src={Pradeep} alt="Justice Pradeep Nandrajog" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Justice Pradeep Nandrajog
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Chief Justice (Retd.) Bombay High Court
              </p>
            </div>
          </div>
          <div>
            <div className="guide_card">
              <img src={Srinath} alt="Mr Srinath Sridharan" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Mr. Srinath Sridharan
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Corporate Leader, CXO Coach
              </p>
            </div>
          </div>
          <div>
            {" "}
            <div className="guide_card">
              <img src={Ashok} alt="Mr Ashok Barat" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Mr. Ashok Barat
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Former MD & CEO, Forbes & Company Ltd
              </p>
            </div>
          </div>
        </Slider>
      )}

      {window.innerWidth > 600 ? (
        <div className="guide_body_wrap advisoryBord">
          <div className="guide_card">
            <img src={Tanu} alt="Ms Tanu Mehta" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Ms. Tanu Mehta
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Legal Counsel, Mediator and Conciliator
            </p>
          </div>
          <div className="guide_card">
            <img src={Ankit} alt="Mr Ankit Sahni" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Mr. Ankit Sahni
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Principal, Ajay Sahni Associates LLP 
            </p>
          </div>

          <div className="guide_card">
            <img src={Raman} alt="Mr Raman Aggarwal" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Mr. Raman Aggarwal
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Director – FIDC, ST Consultant – World Bank 
            </p>
          </div>
        </div>
      ) : (
        <Slider {...settings}>
          <div>
            <div className="guide_card">
              <img src={Tanu} alt="Ms Tanu Mehta" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Ms. Tanu Mehta
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Legal Counsel, Mediator and Conciliator
              </p>
            </div>
          </div>
          <div>
            <div className="guide_card">
              <img src={Ankit} alt="Mr Ankit Sahni" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Mr. Ankit Sahni
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Principal, Ajay Sahni Associates LLP 
              </p>
            </div>
          </div>
          <div>
            {" "}
            <div className="guide_card">
              <img src={Raman} alt="Mr Raman Aggarwal" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Mr. Raman Aggarwal
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Director – FIDC, ST Consultant – World Bank 
              </p>
            </div>
          </div>
        </Slider>
      )}

      <div className="advisory_wrap">
        <div className="guide_card">
          <img
            src={Rajni}
            alt="Late Ms Rajani Iyer"
            style={{ width: "22%", minWidth: "320px" }}
          />
          <h3
            className="greyColor_text"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            Late Ms. Rajani Iyer
          </h3>
          <p
            className="greyColor_text"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            Advisor, Senior Counsel and Mediator 
          </p>
        </div>
      </div>

      <div className="section_heading" style={{ padding: "3em 0 2em 0" }}>
        <h2
          className="blue_heading"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Meet the Founders
        </h2>
      </div>
      {window.innerWidth > 600 ? (
        <div className="guide_body_wrap founders">
          <div className="guide_card">
            <img src={Image4} alt="Bhaven Shah" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Bhaven Shah
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Head - Legal and Strategy
            </p>
          </div>
          <div className="guide_card">
            <img src={Image5} alt="Namita Shah" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Namita Shah
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Head - Product & Finance
            </p>
          </div>

          <div className="guide_card">
            <img src={Image6} alt="Aman Sanghavi" />
            <h3
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Aman Sanghavi
            </h3>
            <p
              className="greyColor_text"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Head - Business & Operations
            </p>
          </div>
        </div>
      ) : (
        <Slider {...settings}>
          <div>
            <div className="guide_card">
              <img src={Image4} alt="Bhaven Shah" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Bhaven Shah
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Head - Legal and Strategy
              </p>
            </div>
          </div>
          <div>
            <div className="guide_card">
              <img src={Image5} alt="Namita Shah" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Namita Shah
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Head - Product & Finance
              </p>
            </div>
          </div>
          <div>
            <div className="guide_card">
              <img src={Image6} alt="Aman Sanghavi" />
              <h3
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Aman Sanghavi
              </h3>
              <p
                className="greyColor_text"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Head - Business & Operations
              </p>
            </div>
          </div>
        </Slider>
      )}
    </div>
  );
}
